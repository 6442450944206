<template>

<div class="col-lg-6 d-flex flex-column" style="height: 40rem;">
    
    <h1 class="mx-auto mb-4 mt-1">Kiitos tilauksesta</h1>
    <icon class="mx-auto mb-4" name="check-circle" scale="5" style="color:#3c3">
    
    </icon>
    
    
    <p class="mx-auto">
        Tilauksenne on vastaanotettu. Pukin vierailun tarkka ajankohta selviää <span class="font-weight-bold">noin viikon sisällä</span> lähetettävästä tilausvahvistuksesta.
    </p>
    
    
    
</div>
    
</template>

<script>
    export default {
        name: 'SuccessComponent',

        data () {
            return {
            }
        },
        components: {
        }
    }
</script>

<style>
    
</style>