<template>
    <div class="col-lg-6 d-flex flex-column" style="height: 40rem;">
    
    <h1 class="mx-auto mb-4 mt-1">Virhe tilatessa</h1>
    <icon class="mx-auto mb-4" name="times-circle" scale="5" style="color:#c33">
    
    </icon>
    
    
    <p class="mx-auto">
        Tilausta tehdessä tapahtui virhe. Jos virhe toistuu, voit tehdä tilauksen sähköpostitse <span class="font-weight-bold">lauttapartio@gmail.com</span>
    </p>
        
    <a href="#" @click="$emit('return')">Palaa tilauslomakkeeseen</a>
    
    
    
</div>
</template>

<script>
    export default {
        name: 'FailComponent',

        data () {
            return {
            }
        },
        components: {
        }
    }
</script>

<style>
    
</style>