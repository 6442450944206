import { render, staticRenderFns } from "./OrderComponent.vue?vue&type=template&id=45c1e204"
import script from "./OrderComponent.vue?vue&type=script&lang=js"
export * from "./OrderComponent.vue?vue&type=script&lang=js"
import style0 from "./OrderComponent.vue?vue&type=style&index=0&id=45c1e204&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports