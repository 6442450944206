<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 left-panel text-light">

        <h1 class="mx-auto font-weight-bold">Lauttapartion Joulupukkipalvelu</h1>

        <p class="text-unimportant">
          Lauttapartio on tarjonnut joulupukkipalvelua osana varainhankintaansa jo 50 -luvulta lähtien. Tilaa
          Lauttapartion perinteinen joulupukki kotiisi aatoksi! Pukin vierailu kestää 10 minuuttia ja maksaa vain 60
          euroa. Joulupukkipalvelun tuotto käytetään Lauttapartion partiotoiminnan hyväksi. Lauttapartion joulupukit
          kiertävät Lauttasaaren ja Ruoholahden alueella.
        </p>
        <p class="font-weight-bold">
          Sähköinen ennakkotilaus jouluksi 2023 käynnistyy tiistaina 21.11. ja jatkuu aattoon asti.
        </p>
        <!--
      <p class="font-weight-bold">
        Tänä vuonna otamme myös huomioon koronasta johtuvia toiveita, noudatamme turvaväliä ja toivottaessa käytämme maskia vierailun ajan.
      </p>

    
      <b-button class="mx-auto" v-b-modal.modal-1>Lue lisää korona-rajoituksista</b-button>

      <b-modal id="modal-1" title="Koronatiedotus">
        <p class="my-4">
          Muun maailman tavoin täytyy myös Lauttapartion joulupukkipalvelun sopeutua vallitsevaan tilanteeseen.
          Tästä syystä on perinteistä palveluamme uudistettu vastaamaan hygienia- ja turvavälitarpeisiin, niin teidän
          asiakkaiden kuin pukkiemmekin osalta. Toivomme pystyvämme kaikesta huolimatta tuomaan tuttuun
          tyyliin mahdollisimman moneen perheeseen oman osamme joulumielestä – kuitenkin turvallisuus
          etusijalla!
        </p>
        <p class="my-4">
          Pukin vierailuissa tullaan noudattamaan seuraavia käytäntöjä:
          - Pukki käyttää aina maskia ja pukkiin pidetään kahden metrin turvaväli koko vierailun ajan. Pukit
          varautuvat myös vastailemaan lasten (ja muiden uteliaiden) kysymyksiin liittyen turvaväleihin ja muihin
          varotoimenpiteisiin.
        </p>
        <p class="my-4">
          - Pukki käyttää hanskoja koko vierailun ajan, eikä jaa lahjoja henkilökohtaisesti. Lahjasäkin pukki voi toki
          tuttuun tyyliin tuoda oven ulkopuolelta.
        </p>

        <p class="my-4">
          - Jos teillä tulee mieleen muita tapoja, joilla pukin vierailu saadaan hoidettua turvallisesti (esim.
          mahdollinen lahjojen tuonti ja vierailu pihalla), ottakaa ihmeessä yhteyttä sähköpostitse. Aattoakin
          varjostava poikkeustilanne vaatii joustamista kummaltakin puolelta, joten olemme avoimia kaikenlaisille
          ehdotuksille.
        </p>
      </b-modal>
    -->
        <!-- <p class="font-weight-bold">

                    Puhelintilauksia otamme vastaan: puh. nro:  040 853 2682
                </p>
                <div class="d-flex flex-column">

                    <div class="font-weight-bold">Aattona 24.12. klo 10 - 12</div>

                </div> -->

        <p class="text-unimportant" style="margin-top:2rem;">
          Jos palvelustamme heräsi kysymyksiä tai haluaisit Lauttapartion pukin muualle pääkaupunkiseudulle, ota
          yhteyttä sähköpostitse osoitteeseen <span class="font-weight-bold text-light">lauttapartio@gmail.com</span>
        </p>

      </div>


      <!-- Tilaus kiinni: -->
      <div class="col-lg-6 right-panel">

      
                <h1 class="mx-auto font-weight-bold">Tilaus jouluksi 2023 on sulkeutunut</h1>

                <p class="font-weight-bold">

                    Vuoden 2024 tilaus avataan marraskuussa.

                </p>
        </div>
        <!-- Tilaus auki: -->
            <!--

      <transition name="fade" enter-active-class="fadeIn" leave-active-class="fadeOut" mode="out-in">
        <keep-alive>
          <component v-bind:is="activeComponent" v-on:onSubmit="onSubmit()" v-bind:form="form" v-on:return="activeComponent = 'OrderComponent'"></component>
        </keep-alive>
      </transition>
          -->

  

    </div>




    <!-- component -->

  </div>



</template>


<script>
import axios from 'axios';
import OrderComponent from '@/components/OrderComponent';

import SuccessComponent from '@/components/SuccessComponent';
import FailComponent from '@/components/FailComponent';


export default {
  name: 'HelloWorld',
  data() {
    return {
      activeComponent: 'OrderComponent',
      sending_form: false,
      api_url: 'https://joulu.lauttapartio.fi/api', //
      //api_url: 'http://127.0.0.1:5000',
      form: {
        address: '',
        email: '',
        name: '',
        additional: '',
        time: '',
        postalcode: '',
        phone: '',
        door: '',
        lat: '',
        lon: ''

      },
      ajat: [{
        text: 'Valitse vierailuaika',
        value: null
      },
        '14-15', '15-17', '17-19', '19-20'
      ],
      show: true
    }
  },
  components: {
    OrderComponent,
    SuccessComponent,
    FailComponent
  },
  methods: {
    onSubmit() {

      console.log(JSON.stringify(this.form));
      console.log(this.api_url + '/tilaus');

      if (this.sending_form == false) {
        axios.post(this.api_url + '/tilaus', this.form)
          .then(res => {

            this.sending_form = false;
            if (res.data.status == "success") {
              this.activeComponent = "SuccessComponent";
            } else {
              this.activeComponent = "FailComponent"
            }

            console.log('response:', res);

          }).catch(e => {
            this.sending_form = false;
            this.activeComponent = "FailComponent"
            console.log(e)
          });
      }
      this.sending_form = true;


    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.container {
  margin-top: 3rem;
  box-shadow: 0px 0px 50px 10px #CCC;

}

.text-unimportant {
  color: #ccc;
}

.left-panel {
  background-position: 90% 90%;
  background: linear-gradient(rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.7) 100%),
    url('../assets/bg.jpg') no-repeat;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

@media(min-width: 1024px) {
  .left-panel {

    height: 59rem;
    background: linear-gradient(rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.7) 100%),
      url('../assets/bg.jpg') no-repeat center center fixed;
  }
}

.right-panel {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
