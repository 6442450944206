<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
	import Vue from 'vue'

	import BootstrapVue from 'bootstrap-vue'

	Vue.use(BootstrapVue);

    require('vue2-animate/dist/vue2-animate.min.css')

    import 'vue-awesome/icons'
    import Icon from 'vue-awesome/components/Icon'

    Vue.component('icon', Icon);

	export default {
		name: 'App'
	}

</script>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css?family=Open+Sans|Overlock+SC');

	@import '../node_modules/bootstrap/scss/bootstrap.scss';
	@import '../node_modules/vue2-animate/src/sass/vue2-animate.scss';


    // Bootstrap and its default variables
@import '../node_modules/bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '../node_modules/bootstrap-vue/src/index.scss';

	h1 {
		font-family: 'Overlock SC', cursive;
	}



	body,
	html {


		font-family: 'Open Sans', sans-serif;
		min-height: 100vh; //overflow-y: hidden;
		//background: linear-gradient( rgba(255, 255, 255, 0.42) 10%, rgba(0, 32, 96, 0.42) 90%),		url('/static/bg.jpg') no-repeat center center fixed; // background-position: center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

	}

	#app {}

</style>
