import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes =

    [
        {
            path: '/',
            name: 'HelloWorld',
            component:  () => import(/* webpackChunkName: "about" */ '../components/HelloWorld')
    },
    {
            path: '/hallinta',
            name: 'Hallinta',
            component:  () => import(/* webpackChunkName: "about" */ '../components/Hallinta')
    },
    {
            path: '/tilaa',
            name: 'FrontComponent',
            component:  () => import(/* webpackChunkName: "about" */  '../components/FrontComponent')
    },
    {
            path: '/lista/:url',
            name: 'PukinLista',
            component:  () => import(/* webpackChunkName: "about" */  '../components/PukinLista')
    }
    ]
    /*[
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ /* '../views/About.vue')
  }
]
*/
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
